import reAssignOrderService from '../services/lock_order.service';
import Vue from "vue";
import i18n from '@/i18n.js'
import router from '@/router'
export const lock_order = {
    namespaced: true,
    state: () => ({
        order: null,
    }),

    mutations: {

        SET_ORDER(state, data) {
            state.order = data
        },
    },
    actions: {
        sign_order({ rootState }, item) {
            return reAssignOrderService.sign_order({ "reassign_order_id": item.id }).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status_id = response.data.data.status_id
                            v.status.name = response.data.data.status.status
                            v.MissedSignCount = response.data.data.MissedSignCount
                            v.signed = "signed"
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        rejected_order_by_tsleem({ rootState }, item) {
            return reAssignOrderService.rejected_order_by_tsleem(item.id).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },

        action_upload_kroky({ rootState, commit }, data) {
            data.append('reassign_order_id', rootState.id)
            return reAssignOrderService.store_kroky(data).then(
                (response) => {
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.kroky_document = response.data.data.kroky_document
                            return v;
                        }
                    });

                    commit('form/SET_NOTIFY', { msg: response.data.message, type: "Success" }, { root: true })
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    }
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },

        action_add_order({ commit, rootState }, data) {
            data.append('type', 'lock')
            return reAssignOrderService.add_order(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.style_form = [];
                    var naw_arr = response.data.data
                    naw_arr.forEach(element => {

                        commit('table/UNSHIFT_ITEMS', element, { root: true })

                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },

        cancel_camp({ rootState }, item) {
            // console.log('item', item.group_id)
            // var count = 0
            // var group_id = item.group_id
            return reAssignOrderService.cancel_camp(item.id).then(
                (response) => {
                    location.reload()
                    // rootState.table.items.map(v => {
                    //     if (v.group_id == group_id && count < 2) {
                    //         count++
                    //         v.group_id = null
                    //         return v;
                    //     }
                    // });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },

        action_re_appointment({ rootState }, data) {
            data.append('order_id', rootState.id)
            return reAssignOrderService.re_appointment(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.style_form = [];
                    // console.log(response.data.appointment)
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.appointment = response.data.data.appointment
                            return v;
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },

        action_reject_order({ rootState, commit }, data) {
            console.log(...data)
            data.append('reassign_order_id', rootState.id)
            return reAssignOrderService.reject_order(data).then(
                (response) => {
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });
                    setTimeout(function () {
                        router.push(`/lock-orders`);
                    }, 1000);
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        update_form_sign({ rootState }, data) {
            data.append('type', 'lock')
            return reAssignOrderService.update_form_signer(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;


                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },

        // not correct


        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            // return reAssignOrderService.get_orders(rootState.table.paginate.page, formData).then(
            formData.append(
                "type",
                'lock'
            );
            return axios.post(rootState.url + '?page=' + rootState.table.paginate.page, formData).then(
                (response) => {
                    // console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },


};